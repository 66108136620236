[data-amplify-authenticator] {
  min-height: 100vh;
}

[data-amplify-theme] {
  min-height: 100vh;
  background-color: var(--colors-neutral01);
}

[data-amplify-container] {
  border-radius: 16px !important;
  overflow: hidden;
  box-shadow: 0px 4px 12px 4px rgba(86, 86, 86, 0.16);
}

[data-amplify-router] {
  border: none !important;
  box-shadow: none !important;
}

[data-amplify-authenticator] [data-amplify-form] {
  padding: 20px 40px !important;
}

.amplify-label {
  font-weight: bold !important;
  font-family: var(--fonts-default);
}

.amplify-input {
  border: 2px solid var(--colors-neutral04) !important;
}

.amplify-input[type='password'] {
  border-right: none !important;
}

.amplify-field__show-password {
  border: 2px solid var(--colors-neutral04) !important;
  border-left: none !important;
}

.amplify-button--primary {
  border-radius: 50px !important;
}

[data-amplify-form] > .amplify-flex {
  gap: 30px !important;
}

.amplify-icon {
  transform: scale(1.4) !important;
}

.amplify-heading {
  font-weight: bold;
  font-size: 20px;
  font-family: var(--fonts-default);
}
