html,
body {
  background-color: #FBFAF7;
  padding: 0;
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

/* .tree {
  font-size: 0.75em;
  display: flex;
  height: 100vh;
}

.tree > :first-child {
  flex: 1;
  overflow: scroll;
  height: 100%;
  width: 100%;
}

.tree .icon {
  cursor: pointer;
}

.tree .icon:empty {
  display: inline-block;
  width: 0.9em;
} */
